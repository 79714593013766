export const apiUrlbase = "https://data.top-channel.tv/euro2024/";
export const mediaUrlbase = "https://data.top-channel.tv/euro2024/euro24_assets/";

export const groupsTeams = {
  'A': ['Germany', 'Scotland', 'Hungary', 'Switzerland'],
  'B': ['Spain', 'Croatia', 'Italy', 'Albania'],
  'C': ['Slovenia', 'Denmark', 'Serbia', 'England'],
  'D': ['Poland', 'Netherlands', 'Austria', 'France'],
  'E': ['Belgium', 'Slovakia', 'Romania', 'Ukraine'],
  'F': ['Türkiye', 'Georgia', 'Portugal', 'Czechia'],
};


export const groups = {
  2762: "A",
  2763: "B",
  2764: "C",
  2765: "D",
  2766: "E",
  2767: "F",
};

export const albNames = {
  Germany: "Gjermani",
  "Deutschland": "Gjermani",
  Turkey: "Turqi",
  "Türkiye": "Turqi",
  Georgia: "Gjeorgji",
  "Czech Republic": "Çeki",
  Czechia: 'Çeki',
  Portugal: "Portugali",
  Romania: "Rumani",
  Belgium: "Belgjikë",
  Slovakia: "Sllovaki",
  Ukraine: "Ukrainë",
  Austria: "Austri",
  Poland: "Poloni",
  France: "Francë",
  Netherlands: "Holandë",
  England: "Angli",
  Serbia: "Serbi",
  Denmark: "Danimarkë",
  Slovenia: "Slloveni",
  Albania: "Shqipëri",
  Italy: "Itali",
  Croatia: "Kroaci",
  Spain: "Spanjë",
  Switzerland: "Zvicër",
  Schweiz: "Zvicër",
  Ungarn: "Hungari",
  Schottland: "Skoci",
  Spanien: "Spanjë",
  Italien: "Itali",
  Albanien: "Shqipëri",
  Kroatien: "Kroaci",
  Niederlande: "Holandë",
  Österreich: "Austri",
  Frankreich: "Francë",
  Polen: "Poloni",
  Dänemark: "Danimarkë",
  Slowenien: "Slloveni",
  Serbien: "Serbi",
  Rumänien: "Rumani",
  Slowakei: "Sllovaki",
  Belgien: "Belgjikë",
  Tschechien: "Çeki",
  Georgien: "Gjeorgji",
  Türkei: "Turqi",
  Hungary: "Hungari",
  Scotland: "Skoci",
};

export const germanNames = {
  Deutschland: "Germany",
  Schweiz: "Switzerland",
  Ungarn: "Hungary",
  Schottland: "Scotland",
  Spanien: "Spain",
  Italien: "Italy",
  Albanien: "Albania",
  Kroatien: "Croatia",
  Niederlande: "Netherlands",
  Österreich: "Austria",
  Frankreich: "France",
  Polen: "Poland",
  Dänemark: "Denmark",
  Slowenien: "Slovenia",
  Serbien: "Serbia",
  England: "England",
  Rumänien: "Romania",
  Ukraine: "Ukraine",
  Slowakei: "Slovakia",
  Belgien: "Belgium",
  Portugal: "Portugal",
  Tschechien: "Czech Republic",
  Georgien: "Georgia",
  Türkei: "Turkey",
};