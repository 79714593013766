import React, { useEffect, useState } from "react";
import { formatDate2, formatTime, getHistory, getMatches } from "./live-score-helpers";
const MatchesCalendarComponent = () => {

  const [pastMatches, setPastMatches] = useState(null);
  const [upcomingMatches, setUpcomingMatches] = useState(null);


  useEffect(() => {
    if (pastMatches === null) {
      async function getPM() {
        const pm = await getHistory();
        setPastMatches(pm);
      }

      getPM();
    }
    if (upcomingMatches === null) {
      async function getUM() {
        const um = await getMatches();
        setUpcomingMatches(um);
      }

      getUM();
    }
  }, [pastMatches, upcomingMatches]);

  return (
    <div className="matches-calendar">
      <h2>Kalendari dhe rezultate</h2>
      {pastMatches !== null && (
        <>
          <div className="matches">
            {pastMatches.map((match, index) => (
              <div className="match" key={`cal-past-` + index}>
                <div className="team">
                  <span className="team-name">{match.homeTeam.albName}</span>
                  <div className="logo">
                    {match.homeTeam.logo && (
                      <img src={match.homeTeam.logo} alt={match.homeTeam.albName} />
                    )}
                  </div>
                </div>
                <div className="res">
                  <span>{match.score}</span>
                  {match.pscore && <span>({match.pscore})</span>}
                </div>
                <div className="team">
                  <div className="logo">
                    {match.awayTeam.logo && (
                      <img src={match.awayTeam.logo} alt={match.awayTeam.albName} />
                    )}
                  </div>
                  <span className="team-name">{match.awayTeam.albName}</span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}


      {upcomingMatches !== null && (
        <div className="matches">
          {upcomingMatches.map((match, index) => (
            <div className="match" key={`cal-past-` + index}>
              <div className="team">
                <span className="team-name">{match.homeTeam.albName}</span>
                <div className="logo">
                  {match.homeTeam.logo && (
                    <img src={match.homeTeam.logo} alt={match.homeTeam.albName} />
                  )}
                </div>
              </div>
              <div className="res">
                <span>{formatDate2(match.date, match.time)}</span>
                <span>{formatTime(match.date, match.time)}</span>
              </div>
              <div className="team">
                <div className="logo">
                  {match.awayTeam.logo && (
                    <img src={match.awayTeam.logo} alt={match.awayTeam.albName} />
                  )}
                </div>
                <span className="team-name">{match.awayTeam.albName}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MatchesCalendarComponent;
