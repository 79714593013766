import React from 'react';
import ReactDOM from 'react-dom/client';
import "./App.scss";
import UpcomingMatchesComponent from './UpcomingMatchesComponent';
import MatchesCalendarComponent from './MatchesCalendarComponent';

const root = ReactDOM.createRoot(document.getElementById('root'));

const matchesRootEl = document.getElementById('matchesRoot');
const allBlocksEl = document.getElementById('allBlocks');

root.render(
  <React.StrictMode>
    <>
      {matchesRootEl !== undefined && matchesRootEl !== null ? <UpcomingMatchesComponent /> : null}

      {allBlocksEl !== null && allBlocksEl !== undefined && (
        <div className="euro-wrapper">
          <div className="euro-inner">
            {/* <GroupStandingsComponent /> */}
            <MatchesCalendarComponent />
          </div>
        </div>
      )}
    </>
  </React.StrictMode>
);

