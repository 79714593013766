import React, { useEffect, useState } from "react";
import { getDateTime, getLiveMatches, getMatches } from "./live-score-helpers";

const UpcomingMatchesComponent = () => {
  const [liveMatches, setLiveMatches] = useState(null);
  const [upcomingMatches, setUpcomingMatches] = useState(null);


  useEffect(() => {
    if (liveMatches === null) {
      async function getLM() {
        const lm = await getLiveMatches();
        setLiveMatches(lm);
      }

      getLM();
    }
    if (upcomingMatches === null) {
      async function getUM() {
        const um = await getMatches();
        setUpcomingMatches(um.length <= 10 ? um : um.slice(0, 10));
      }

      getUM();
    }

    const interval = setInterval(async () => {
      console.log("fetching data upcoming");
      const lm = await getLiveMatches();
      setLiveMatches(lm);

      const um = await getMatches();
      setUpcomingMatches(um.length <= 10 ? um : um.slice(0, 10));
    }, 60000);

    return () => clearInterval(interval);
  }, [liveMatches, upcomingMatches]);

  return (
    <>
      <div className="upcoming-matches">
        <div className="matches">
          {liveMatches !== null && upcomingMatches !== null && [...liveMatches, ...upcomingMatches].map((match, index) => (
            <MatchCard key={index} match={match} />
          ))}
        </div>
      </div>
    </>
  );
};

const MatchCard = ({ match }) => {
  let matchClasses = ["match-card"];
  if (match.isLive) matchClasses.push("live");

  let homeScore = "";
  let awayScore = "";

  if (match.isLive) {
    let splitScore = match.score.split("-");
    let splotPScore = match.pscore.split("-");
    if (splitScore.length === 2) {
      homeScore = splitScore[0].trim();
      awayScore = splitScore[1].trim();

      if (homeScore === "?") homeScore = "0";
      if (awayScore === "?") awayScore = "0";
    }

    if (splotPScore.length === 2) {
      homeScore += " (" + splotPScore[0].trim() + ")";
      awayScore += " (" + splotPScore[1].trim() + ")";
    }
  }

  let matchTime = "";
  if (match.isLive) {
    matchTime = match.time;
    if (matchTime.indexOf(":") === -1) {
      matchTime = 'LIVE ' + matchTime + "'";
    } else {
      matchTime = 'LIVE';
    }
  } else {
    matchTime = getDateTime(match.date, match.time);
  }

  return (
    <div className={matchClasses.join(" ")}>
      <div className="info">
        <span>
          {matchTime}
        </span>
      </div>
      <div className="teams">
        <div className="team">
          <div className="logo">
            {match.homeTeam.logo && (
              <img src={match.homeTeam.logo} alt={match.homeTeam.albName} />
            )}
          </div>
          <span className="team-name">{match.homeTeam.albName}</span>
          {homeScore !== "" && <span>{homeScore}</span>}
        </div>
        <div className="team">
          <div className="logo">
            {match.awayTeam.logo && (
              <img src={match.awayTeam.logo} alt={match.awayTeam.albName} />
            )}
          </div>
          <span className="team-name">{match.awayTeam.albName}</span>
          {awayScore !== "" && <span>{awayScore}</span>}
        </div>
      </div>
    </div>
  );
};

export default UpcomingMatchesComponent;
