import { albNames, apiUrlbase, germanNames, mediaUrlbase } from "./constants";

export const getAlbName = (n) => {
  return albNames[n] ?? "";
};

export const getFlag = (n) => {
  if (albNames[n] === undefined || albNames[n] === null) return null;
  if (n === "Czechia") n = "Czech Republic";
  if (n === "Türkiye") n = "Turkey";
  if (germanNames[n] !== undefined) n = germanNames[n];
  return mediaUrlbase + n + ".png";
};

export const getDateTime = (date, time) => {
  let dt = new Date(date + "T" + time + "Z");
  let day = dt.toLocaleString("en-US", {
    timeZone: "Europe/Tirane",
    day: "numeric",
  });

  let month =
    dt.toLocaleString("en-US", {
      timeZone: "Europe/Tirane",
      month: "long",
    }) === "June"
      ? "Qershor"
      : "Korrik";

  let tm = dt.toLocaleString("en-US", {
    timeZone: "Europe/Tirane",
    hour12: false,
    hour: "numeric",
    minute: "numeric",
  });

  return day + " " + month + ", " + tm;
};

export const formatDate = (date, time) => {
  let dt = new Date(date + "T" + time + "Z");
  let day = dt.toLocaleString("en-US", {
    timeZone: "Europe/Tirane",
    day: "numeric",
  });

  let month =
    dt.toLocaleString("en-US", {
      timeZone: "Europe/Tirane",
      month: "long",
    }) === "June"
      ? "Qershor"
      : "Korrik";

  return day + " " + month;
};

export const formatDate2 = (date, time) => {
  let dt = new Date(date + "T" + time + "Z");
  let day = dt.toLocaleString("en-US", {
    timeZone: "Europe/Tirane",
    day: "numeric",
  });

  let month =
    dt.toLocaleString("en-US", {
      timeZone: "Europe/Tirane",
      month: "long",
    }) === "June"
      ? "QER"
      : "KOR";

  return day + " " + month;
};

export const formatTime = (date, time) => {
  let dt = new Date(date + "T" + time + "Z");
  let tm = dt.toLocaleString("en-US", {
    timeZone: "Europe/Tirane",
    hour12: false,
    hour: "numeric",
    minute: "numeric",
  });

  return tm;
};

export const getLiveMatches = async () => {
  const liveEndpoint = apiUrlbase + "live.json";
  try {
    const response = await fetch(liveEndpoint);
    const data = await response.json();
    if (data?.length === 0) return [];
    let finalMatches = [];
    data.forEach((d) => finalMatches.push(transformMatches(d, true)));
    return finalMatches;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getMatches = async () => {
  const liveEndpoint = apiUrlbase + "matches.json";
  try {
    const response = await fetch(liveEndpoint);
    const data = await response.json();
    if (data?.length === 0) return [];
    let finalMatches = [];
    data.forEach((d) => finalMatches.push(transformMatches(d)));
    // console.log(finalMatches);
    return finalMatches;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const transformMatches = (match, isLive = false) => {
  let theHomeName = !isLive ? match?.home_name : match?.home?.name;
  let theAwayName = !isLive ? match?.away_name : match?.away?.name;
  let mx = {
    isLive: isLive,
    date: !isLive ? match.date : null,
    time: match.time,
    homeTeam: {
      name: theHomeName,
      albName: getAlbName(theHomeName),
      logo: getFlag(theHomeName),
    },
    awayTeam: {
      name: theAwayName,
      albName: getAlbName(theAwayName),
      logo: getFlag(theAwayName),
    },
    score: isLive ? match?.scores?.score : "",
    pscore: isLive ? match?.scores?.ps_score : "",
  };

  if (match.score !== undefined) {
    mx.score = match.score;
  }

  if (match.ps_score !== undefined) {
    mx.pscore = match.ps_score;
  }

  return mx;
};

export const getHistory = async () => {
  const liveEndpoint = apiUrlbase + "scores.json";
  try {
    const response = await fetch(liveEndpoint);
    const data = await response.json();
    if (data?.length === 0) return [];
    let finalMatches = [];
    data.forEach((d) => finalMatches.push(transformMatches(d)));
    // console.log(finalMatches);
    return finalMatches;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getStandings = async () => {
  const liveEndpoint = apiUrlbase + "standings.json";
  try {
    const response = await fetch(liveEndpoint);
    const data = await response.json();
    if (data?.length === 0) return [];
    // console.log(data);
    let finalStandings = transformStandings(data);
    // console.log(finalStandings);
    return finalStandings;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const transformStandings = (standings) => {
  let groups = [];
  standings.forEach((standing) => {
    let group = groups.find((group) => group.name === standing.group_name);
    if (!group) {
      group = { name: standing.group_name, teams: [] };
      groups.push(group);
    }
    group.teams.push({
      name: getAlbName(standing.name),
      logo: getFlag(standing.name),
      win: standing.won,
      draw: standing.drawn,
      loss: standing.lost,
      goalDifference: standing.goal_diff,
      points: standing.points,
      matches: standing.matches,
      rank: standing.rank,
    });
  });
  groups = sortStandings(groups);
  return groups;
};

const sortStandings = (standings) => {
  standings.forEach((group) => {
    group.teams.sort((a, b) => a.rank - b.rank);
  });
  return standings;
};
